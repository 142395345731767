import { useEffect, useRef } from 'react';

// TODO: fix in [EPIC-1083]
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const usePreviousState = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
