import ReviewCarouselComponent from 'components/reviewCarousel';
import React from 'react';
import { ReviewCarouselSlice } from 'types.generated';

export const ReviewCarousel = ({ slice }: { slice: ReviewCarouselSlice }) => {
  const reviews = slice.items.filter(
    (item) => !!item.title && !!item.review && !!item.consumer && !!item.url
  );
  return (
    <ReviewCarouselComponent reviewContent={reviews as ReviewCarouselProps} />
  );
};
